import React from 'react';
import RouteList from "./router/RouteList";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
        <RouteList />
  );
}

export default App;
