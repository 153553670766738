import {Routes, Route, useNavigate, Navigate} from "react-router-dom";
import DashboardLayout from "../views/layout/DashboardLayout";
import Login from "../views/Login";
import React from "react";
import {PrivateRoute} from "./PrivateRoute";
import {isAuthenticated} from "../services/AuthenticationService";
import UnitAvailabilityList from "../components/unitAvailability/UnitAvailabilityList";
import EditUnitAvailability from "../components/unitAvailability/EditUnitAvailability";
import CreateUnitAvailability from "../components/unitAvailability/CreateUnitAvailability";
import EditBooking from "../components/bookings/EditBooking";
import ResetPassword from "../views/ResetPassword";
import ResetPasswordLinkExpired from "../views/ResetPasswordLinkExpired";
import ForgotPassword from "../views/ForgotPassword";
import MonthList from "../components/bookings/MonthList";
import DayList from "../components/bookings/DayList";

const RouteList = () => {
    return (
        <Routes>
            <Route path="/"  element={
                <PrivateRoute isAuthenticated={isAuthenticated()}>
                    <DashboardLayout />
                </PrivateRoute>
            }>

                {/* Unit Availability */}
                {/*<Route path="/units-availability"  element={<UnitAvailabilityList />}/>*/}
                {/*<Route path="/units-availability/:id"  element={<EditUnitAvailability />}/>*/}
                {/*<Route path="/units-availability/create"  element={<CreateUnitAvailability />}/>*/}

                {/* Bookings */}
                <Route path="/month"  element={<MonthList />}/>
                <Route path="/day"  element={<DayList />}/>
                <Route path="/booking/:id"  element={<EditBooking />}/>

            </Route>

            <Route path="/login"  element={
                isAuthenticated() ? <Navigate to="/day" replace={true} /> : <Login />
            }/>
            {/* Forgot Password */}
            <Route path="/forgot-password"  element={<ForgotPassword />}/>
            {/* Forgot Password */}
            <Route path="/reset-password/:token"  element={<ResetPassword />}/>
            {/* Reset Password Link Expired*/}
            <Route path="/reset-password/expired"  element={<ResetPasswordLinkExpired />}/>
        </Routes>
    )
}

export default RouteList;