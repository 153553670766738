// export const API = "http://127.0.0.1:8000/api";
export const API = "https://api.muuttoja.fi/api";

export const GMAPKEY = "AIzaSyCzcvmKLAUO3TdD78Pc8Z0sYpJmntfnLc0";

export const HEADERS = {
    'Content-Type': 'application/json',
    // 'Authorization': `Bearer ${sessionStorage.getItem('token')}`
    'Authorization': `Bearer ${localStorage.getItem('token')}`
}

export const getCompanyId = async () => {
    // let companyItem: any = sessionStorage.getItem("company");
    let companyItem: any = localStorage.getItem("company");
    let { id } = JSON.parse(companyItem);

    return id;
}